<template>
  <div>工单配件</div>
</template>

<script>
export default {
  name: 'orderParts',
  data () {
    return {}
  }
}
</script>

<style scoped>
</style>
